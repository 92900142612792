.level-template {
    position: relative;
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 2rem;
    font-family: Asap, Arial, sans-serif;
  }
  
  .level-template h1 {
    font-size: 2.5rem;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  }
  
  .level-template-buttons {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .level-template-buttons button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: #8EB34C;
    color: white;
    transition: background-color 0.3s;
  }
  
  .level-template-buttons button:hover {
    background-color: #45a049;
  }