.main-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    --tw-bg-opacity: 1;
    background-color: rgb(232 234 220 / var(--tw-bg-opacity));
    font-family: Asap, Arial, sans-serif;
  }
  
  .main-title {
    font-size: 3rem;
    color: #333;
    margin-bottom: 2rem;
  }

  .main-menu-image-container {
    width: 80%;
    max-width: 400px;
    margin: 20px 0;
    overflow: hidden;
    border-radius: 10px;
  }
  
  .main-menu-image {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .button-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .menu-button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: black;
    background-color: white;
    border: none;
    border-radius: 9999px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .menu-button:hover {
    background-color:  #8EB34C;
  }

  .clock-container {
    transition: all 0.3s ease;
  }
  
  .clock-container.alert {
    color: red;
    font-size: 45px;
    animation: pulse 0.6s ease-in-out infinite alternate, backgroundColorChange 0.6s ease-in-out infinite alternate;
  }

  .clock-container.normal {
    font-size: 30px;
    color: white;
  }
  
  @keyframes pulse {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.1);
    }
  }
  
  @keyframes backgroundColorChange {
    from {
      color: red;
      background-color: pink;
    }
    to {
      color: pink;
      background-color: rgb(248, 54, 86);
    }
  }