.timer-container {
    width: 100%;
    height: 20px; /* Adjust the height as needed */
    background-color: #e0e0e0; /* Background color of the container */
    position: relative;
    overflow: hidden;
}

.bar-wipe {
    height: 100%;
    width: 100%; /* Full width */
    background-color: yellow; /* Initial color of the timer bar */
    position: absolute;
    left: 0;
}

.indicator {
    height: 100%;
    width: 10%; /* Width of the black indicator */
    background-color: black; /* Color of the indicator */
    position: absolute;
    left: 0; /* Start from the left */
}