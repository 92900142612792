.tutorial-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .tutorial-content {
    background-color: white;
    padding: 2rem;
    border-radius: 0.5rem;
    max-width: 80%;
    max-height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .tutorial-image {
    max-width: 100%;
    max-height: 70vh; 
    width: auto;      
    height: auto;      
    object-fit: contain;
    margin-bottom: 1rem;
  }
  
  .tutorial-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .tutorial-button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    border: none;
    border-radius: 0.25rem;
    transition: background-color 0.3s ease;
  }
  
  .skip-button {
    background-color: #e53e3e;
    color: white;
  }
  
  .skip-button:hover {
    background-color: #c53030;
  }
  
  .next-button {
    background-color: #48bb78;
    color: white;
  }
  
  .next-button:hover {
    background-color: #38a169;
  }