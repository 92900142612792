.overlay-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.overlay-content {
  --tw-bg-opacity: 1;
  background-color: rgb(232 234 220 / var(--tw-bg-opacity));
  padding: 30px;
  border-radius: 15px;
  text-align: center;
  max-width: 80%;
  width: 400px;
}

.overlay-title {
  font-size: 30px;
  margin-bottom: 50px;
  color: #007b9e;
}

.overlay-info {
  margin-bottom: 15px;
}

.overlay-button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
}

.overlay-button {
  margin-right: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 9999px;
  transition: background-color 0.3s;
}

.continue-button,
.retry-button {
  background-color: white;
}

.continue-button:hover,
.retry-button:hover {
  background-color: #8eb34c;
}

.level-select-button {
  background-color: #008cba;
  color: white;
}

.level-select-button:hover {
  background-color: #007b9e;
}

.auth-button {
  background-color: #007b9e;
  color: white;
}

.auth-button:hover {
  background-color: #00058e;
}

.behind-recipe-effect, .behind-recipe-effect-failure {
  position: relative;
  display: inline-block;
  opacity: 0;
}

.success-image, .failure-image {
  width: 150px;
  height: auto;
  position: relative;
  z-index: 2;
}

.behind-recipe-effect::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 400px;
  background: radial-gradient(
    circle,
    rgba(255, 230, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 70%
  );
  transform: translate(-50%, -50%);
  z-index: 1;
  pointer-events: none;
}


.behind-recipe-effect-failure::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 400px;
  background: radial-gradient(
    circle,
    rgba(236, 32, 32, 0.747) 0%,
    rgba(0, 0, 0, 0) 70%
  );
  transform: translate(-50%, -50%);
  z-index: 1;
  pointer-events: none;
}

.star-score {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.star-icon {
  width: 40px;
  height: 40px;
  margin: 0 5px;
}

.intro-image {
  width: 200px;
  height: auto;
  position: relative;
  z-index: 2;
  margin-bottom: 20px;
}

.play-button {
  background-color: #8eb34c;
  color: white;
  width: 100%;
}

.play-button:hover {
  background-color: #45a049;
}
