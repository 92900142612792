.info-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    --tw-bg-opacity: 1;
    background-color: rgb(232 234 220 / var(--tw-bg-opacity));
    font-family: Asap, Arial, sans-serif;
  }
  
  .info-screen h1 {
    font-size: 3rem;
    color: #333;
    margin-bottom: 2rem;
  }
  
  .info-screen p {
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 20px;
    margin-left: 30%;
    margin-right: 30%;
    text-align: center;
  }
  
  .button-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .back-button, .next-button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: black;
    background-color: white;
    border: none;
    border-radius: 9999px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .back-button:hover {
    background-color:  #8EB34C;
  }
  
  .next-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .next-button.enabled:hover {
    background-color:  #8EB34C;
  }