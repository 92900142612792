.auth-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2); /* Match overlay background color */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.auth-content {
    background-color: white;
    padding: 2rem;
    border-radius: 0.5rem;
    max-width: 80%;
    max-height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.auth-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.auth-input {
    flex: 1; 
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
}

.auth-button, .auth-toggle-button, .auth-close-button, .auth-logout-button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    border: none;
    border-radius: 0.25rem;
    transition: background-color 0.3s ease;
    margin: 0.5rem;
}

.auth-buttons {
    display: flex;
    justify-content: flex-end; /* Align both buttons to the right */
    margin-top: 1rem;
}

.auth-button {
    background-color: #48bb78;
    color: white;
    transition: background-color 0.3s ease;
}

.auth-button:hover {
    background-color: #38a169; /* Hover color to match the next button */
}

.auth-toggle-button {
    background-color: #3182ce;
    color: white;
}

.auth-toggle-button:hover {
    background-color: #2b6cb0;
}

.auth-close-button {
    background-color: #e53e3e; /* Same color as the skip button */
    color: white;
}

.auth-close-button:hover {
    background-color: #c53030; /* Hover color to match the skip button */
}

.auth-logout-button {
    background-color: #f56565; /* Red color for the logout button */
    color: white;
}

.auth-logout-button:hover {
    background-color: #e53e3e; /* Darker red on hover */
}

.auth-button:disabled,
.auth-toggle-button:disabled,
.auth-close-button:disabled,
.auth-logout-button:disabled {
    background-color: #d3d3d3; /* Light grey background */
    color: #a9a9a9; /* Darker grey text */
    cursor: not-allowed; /* Change cursor to indicate inactivity */
    pointer-events: none; /* Prevent interaction */
}

.auth-status-message {
    margin-top: 1rem;
    color: green;
    font-size: 1rem;
}
