.level-select {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    --tw-bg-opacity: 1;
    background-color: rgb(232 234 220 / var(--tw-bg-opacity));
    min-height: 100vh;
  }
  
  .level-select h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 2rem;
  }
  
  .level-buttons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    width: 100%;
    max-width: 800px;
  }
  
  
  .level-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background-color: #fff;
    border: 2px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .level-button:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .level-number {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .star-rating {
    display: flex;
    justify-content: center;
  }
  
  .star {
    width: 20px;
    height: 20px;
    margin: 0 2px;
  }

  .back-button {
    margin-top: 20px;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: black;
    background-color: white;
    border: none;
    border-radius: 9999px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .back-button:hover {
    background-color:  #8EB34C;
  }